<template>
  <a :href="$router.resolve(route).href" class="float">
    <span class="wy-icon-add"></span>
  </a>
</template>

<script>

export default {
  name: 'FloatingButton',
  props: {
    action: Function,
    route: {
      type: Object,
    },
  },
}

</script>

<style lang="scss" scoped>
.float {
  position: fixed;
  bottom: 30px;

  [dir=ltr] & {
    right: 30px;
  }
  [dir=rtl] & {
    left: 30px;
  }
  background-color: #06AD97;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  //box-shadow: 2px 2px 3px #999;
  z-index: 99999;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  opacity: .5;
  transition: all 350ms ease;
  &:hover {
    opacity: 1;
  }

  span {
    font-size: 33px;
    cursor: pointer;
    text-align: center;
    margin: 0!important;
  }
}

.my-float {

}
</style>
