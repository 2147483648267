<template>
  <div class="wy-list-page">

    <floating-button :route="{ name: 'Customer' }"/>
    <CCard>
      <CCardBody>

        <CAlert color="danger" v-if="!_.isEmpty(error)" closeButton @update:show="error = ''">
          {{ error }}
        </CAlert>

        <CAlert color="success text-center" v-if="!_.isEmpty(message)">
          {{ message }}
        </CAlert>

        <div v-if="!loaded">
          <inline-loader/>
        </div>

        <div v-if="loaded">
          <div class="wy-search-input-container" v-if="_.isEmpty(message)">
            <input
                class="form-control full-width"
                :placeholder="$t('type_here_to_search')"
                type="text"
                v-model="search"
                @input="runSearch"
                @keydown.esc="clearSearch"
            />
            <button class="wy-clear-button" @click="clearSearch" v-if="search !== ''">
              <span class="wy-icon-clear-input"></span>
            </button>
          </div>

          <CAlert color="success text-center" class="mt-3" v-if="noSearchResult">
            {{ $t('no_result_found') }}
          </CAlert>

          <div v-if="!_.isEmpty(customers)">
            <div class="table-responsive-md">
              <table class="table table-hover wy-list-table">
                <thead>
                <tr>
                  <th style="width: 50px;">#</th>
                  <th>{{ $t('name') }}</th>
                  <th>{{ $t('email') }}</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(customer, customerIndex) in customers"
                    :key="`customer_${customerIndex}`"
                    :class="{ 'wy-blocked-table-row': customer.blocked }">
                  <td>{{ customer.id }}</td>
                  <td>
                    <strong>{{ customer.full_name }}</strong>
                    <div class="text-muted">{{ customer.short_name }}</div>
                  </td>
                  <td>
                    {{ customer.email }}
                  </td>
                  <td style="width: 120px; white-space: nowrap;">
                    <div class="row">
                      <div class="col">
                        <CButton color="wy-action-button p-0" @click="toggleBlock(customerIndex)" :class="{ 'text-red': customer.blocked }"><i class="wy-icon-ban p-0"></i></CButton>
                      </div>
                      <div class="col">
                        <CButton color="wy-action-button p-0" @click="editCustomer(customerIndex)"><i class="wy-icon-pencil p-0"></i></CButton>
                      </div>
                      <div class="col">
                        <CButton color="wy-action-button p-0" @click="deleteCustomer(customerIndex)"><i class="wy-icon-delete p-0"></i></CButton>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

            <div class="row wy-pagination-info">
              <div class="col">
                {{ $t('from') }} {{ from }}
                {{ $t('to') }} {{ to }}
              </div>
              <div class="col text-right">
                <strong>{{ $t('total') }}: {{ total }}</strong>
              </div>
            </div>

            <CPagination
                align="center"
                :pages="lastPage"
                :active-page.sync="currentPage"
            />
          </div>
        </div>

      </CCardBody>
    </CCard>

  </div>
</template>

<script>
import { customers, deleteCustomer, unBlockCustomer, blockCustomer } from "../../../api/customer";
import FloatingButton from "../../components/FloatingButton";
import InlineLoader from "../../components/InlineLoader";
import _ from "lodash";

export default {
  name: 'CustomersList',
  components: {
    FloatingButton,
    InlineLoader,
  },
  data() {
    return {
      currentPage: 0,
      activePage: 0,
      firstPage: 0,
      lastPage: 0,
      perPage: 0,
      total: 0,
      from: 0,
      to: 0,
      message: '',
      error: '',
      search: '',
      loaded: false,
      noSearchResult: false,
      //
      customers: [],
    };
  },
  watch: {
    currentPage(newValue) {
      this.getCustomers();
      this.$router.replace({name: this.$route.name, params: this.$route.params, query: {page: newValue}});
    },
    '$route.query.page': function (page) {
      if (this._.isUndefined(page)) {
        page = 1;
      }
      this.error = '';
      this.currentPage = page;
    }
  },
  mounted() {
    this.currentPage = Number(this.$route.query.page) || 1;
    this.getCustomers();
    this.firstPage = 1;
  },
  methods: {
    getCustomers() {
      this.noSearchResult = false;
      customers(this, this.currentPage, this.search, (response) => {
        this.customers = response.customers.data;
        this.lastPage = Number(response.customers.last_page);
        this.perPage = Number(response.customers.per_page);
        this.total = Number(response.customers.total);
        this.from = Number(response.customers.from);
        this.to = Number(response.customers.to);
        this.loaded = true;
        if (this._.isEmpty(this.customers)) {
          if (!this._.isEmpty(this.search)) {
            this.noSearchResult = true;
          } else {
            this.message = this.$t('empty_list');
          }
        }
        this.$forceUpdate();
      });
    },
    clearSearch() {
      if (this.search !== '') {
        this.search = '';
        this.getCustomers();
      }
    },
    runSearch: _.debounce(function() {
      this.currentPage = 1;
      this.getCustomers();
    }, 500),
    editCustomer(customerIndex) {
      this.$router.push({ name: 'Customer', params: {customer_id: this.customers[customerIndex]['id'] } });
    },
    deleteCustomer(customerIndex) {
      this.$store.commit('confirmation', {
        message: this.$t('are_you_sure_that_you_want_to_delete_this_customer', { customerName: this.customers[customerIndex].full_name }),
        onOk: () => {
          this.error = '';
          this.message = '';
          deleteCustomer(this, this.customers[customerIndex]['id'], (response) => {
            this.$delete(this.customers, customerIndex);
            this.total -= 1;
            this.message = response.message;
            if (this.total > this.customers.length && this.customers.length > 0 && this.currentPage !== this.lastPage) {
              this.getCustomers();
            } else if (this.customers.length === 0 && this.currentPage > 1) {
              this.currentPage -= 1;
            }
            this.$forceUpdate();
          }, (error) => {
            this.error = error.message;
          });
        }
      })
    },
    toggleBlock(customerIndex) {
      if (this.customers[customerIndex]['blocked']) {
        unBlockCustomer(this, this.customers[customerIndex]['id'], () => {
          this.customers[customerIndex]['blocked'] = false;
        }, (error) => {
          this.error = error.message;
        });
      } else {
        blockCustomer(this, this.customers[customerIndex]['id'], () => {
          this.customers[customerIndex]['blocked'] = true;
        }, (error) => {
          this.error = error.message;
        });
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.wy-list-page {
  .wy-search-input-container::v-deep {
    position: relative;
    input {
      background-color: #F1F1F1;
      [dir=ltr] & {
        padding-right: 40px;
      }

      [dir=rtl] & {
        padding-left: 40px;
      }
    }
    button.wy-clear-button {
      position: absolute;
      background: none;
      border: none;
      top: 8px;
      color: #C41117;
      transition: color 350ms ease;
      [dir=ltr] & {
        right: 5px;
      }

      [dir=rtl] & {
        left: 5px;
      }
      span {
        margin: 0!important;
        font-size: 24px;
      }
      &:focus {
        outline: none;
      }
      &:hover {
        color: #222222;
      }
    }
  }
  .wy-list-table {
    thead {
      th {
        border-top-width: 0;
      }
    }
  }
}
</style>
