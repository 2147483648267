import axios from "./_base";
import errorHandler from "./_errorHandler";

export const customers = (vue, page, search, callback) => {
    search = search.trim();
    let url = `http://192.168.43.96/api/customers?page=${page}`;
    if (search !== '') {
        url = `${url}&search=${search}`
    }

    axios.get(url, {
        headers: {
            'Authorization': `Bearer ${vue.$store.state.user.token}`,
        },
    }).then((response) => {
        callback(response.data);
    }).catch((error) => {
        errorHandler(vue, error)
    });
};

export const insertCustomer = (vue, customer, callback, errorCallback) => {
    customer.blocked = customer.blocked ? 1: 0;
    axios.post(`http://192.168.43.96/api/insert_customer`, customer, {
        headers: {
            'Authorization': `Bearer ${vue.$store.state.user.token}`,
        },
    }).then((response) => {
        callback(response.data);
    }).catch((error) => {
        errorHandler(vue, error, errorCallback)
    });
};

export const updateCustomer = (vue, customer, callback, errorCallback) => {
    customer.blocked = customer.blocked ? 1: 0;
    axios.post(`http://192.168.43.96/api/update_customer/${customer.id}`, customer, {
        headers: {
            'Authorization': `Bearer ${vue.$store.state.user.token}`,
        },
    }).then((response) => {
        callback(response.data);
    }).catch((error) => {
        errorHandler(vue, error, errorCallback)
    });
};

export const getCustomer = (vue, id, callback, errorCallback) => {
    axios.get(`http://192.168.43.96/api/get_customer/${id}`, {
        headers: {
            'Authorization': `Bearer ${vue.$store.state.user.token}`,
        },
    }).then((response) => {
        callback(response.data);
    }).catch((error) => {
        errorHandler(vue, error, errorCallback)
    });
};

export const getEmptyCustomer = (vue, callback, errorCallback) => {
    axios.get('http://192.168.43.96/api/get_empty_customer', {
        headers: {
            'Authorization': `Bearer ${vue.$store.state.user.token}`,
        },
    }).then((response) => {
        callback(response.data);
    }).catch((error) => {
        errorHandler(vue, error, errorCallback)
    });
};

export const deleteCustomer = (vue, id, callback, errorCallback) => {
    axios.post(`http://192.168.43.96/api/delete_customer`, {
        id,
    }, {
        headers: {
            'Authorization': `Bearer ${vue.$store.state.user.token}`,
        },
    }).then((response) => {
        callback(response.data);
    }).catch((error) => {
        errorHandler(vue, error, errorCallback)
    });
};

export const blockCustomer = (vue, id, callback, errorCallback) => {
    axios.get(`http://192.168.43.96/api/block_customer/${id}`, {
        headers: {
            'Authorization': `Bearer ${vue.$store.state.user.token}`,
        },
    }).then((response) => {
        callback(response.data);
    }).catch((error) => {
        errorHandler(vue, error, errorCallback)
    });
};

export const unBlockCustomer = (vue, id, callback, errorCallback) => {
    axios.get(`http://192.168.43.96/api/unblock_customer/${id}`, {
        headers: {
            'Authorization': `Bearer ${vue.$store.state.user.token}`,
        },
    }).then((response) => {
        callback(response.data);
    }).catch((error) => {
        errorHandler(vue, error, errorCallback)
    });
};
